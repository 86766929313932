import React, { useEffect, useState } from 'react'
import {
  Typography,
  Paper,
  makeStyles,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  IconButton,
  TextField,
  Button,
  TableSortLabel,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TablePagination,
  Box,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core'
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Refresh,
  Delete as DeleteIcon,
  CloudDownload,
  Visibility,
  CloudUpload,
  SettingsBackupRestore,
} from '@material-ui/icons'
import { useToasts } from 'react-toast-notifications'
import Dropzone from 'react-dropzone'
import lodash from 'lodash'

import api from '../dataProvider'
import { IConversationParams } from '../types'
import { displayDuration, processName, displayAudioTime } from '../util'

const useStyles = makeStyles((theme) => ({
  filter: {
    padding: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 130,
  },
  resetButton: {
    margin: theme.spacing(1),
  },
}))

export default function Conversations() {
  const classes = useStyles()

  const [status, setStatus] = useState('')
  const [language, setLanguage] = useState('')
  const [refresh, setRefresh] = useState(true)
  const [createFromVal, setCreateFromVal] = useState('')
  const [createToVal, setCreateToVal] = useState('')
  const [searchKey, setSearchKey] = useState('')
  const [hostSearchKey, setHostSearchKey] = useState('')
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(25)
  const [sortKey, setSortKey] = useState(
    'createDesc' as 'createAsc' | 'updateAsc' | 'createDesc' | 'updateDesc',
  )
  const [conversations, setConversations] = useState([] as Array<any>)
  const [total, setTotal] = useState(0)
  // const [numSpeakersGte, setNumSpeakerGte] = useState('')
  // const [numSpeakersLte, setNumSpeakerLte] = useState('')

  const { addToast } = useToasts()

  const handleReset = () => {
    setStatus('')
    setCreateFromVal('')
    setCreateToVal('')
    setSearchKey('')
    setHostSearchKey('')
    setLanguage('')
    // setNumSpeakerGte('')
    // setNumSpeakerLte('')
  }

  useEffect(() => {
    if (searchKey === '') {
      ;(document.querySelector('#conversation-search') as any).value = '' // to set the search field as blank
    }
    if (hostSearchKey === '') {
      ;(document.querySelector('#conversation-search-host') as any).value = '' // to set the search field as blank
    }
  }, [searchKey, hostSearchKey])

  useEffect(() => {
    const conversationParams: IConversationParams = {
      status: status,
      language: language,
      createdDateStart: createFromVal
        ? new Date(createFromVal).toISOString()
        : '',
      createdDateEnd: createToVal ? new Date(createToVal).toISOString() : '',
      searchKey: searchKey,
      hostSearchKey: hostSearchKey,
      // numSpeakersGte: numSpeakersGte,
      // numSpeakersLte: numSpeakersLte,
    }
    const params: IConversationParams = {}
    Object.keys(conversationParams).forEach((param) => {
      if ((conversationParams as any)[param])
        (params as any)[param] = (conversationParams as any)[param]
    })
    api
      .getConversations({ page, limit, sortKey, ...params })
      .then((data) => {
        // console.log(params)
        setConversations(data.docs)
        setTotal(data.totalDocs)
      })
      .catch((err) => {
        console.log(err)
        addToast(`Failed to fetch conversations: ${err.message}`, {
          appearance: 'error',
          autoDismiss: false,
        })
      })
  }, [
    page,
    limit,
    sortKey,
    addToast,
    createFromVal,
    createToVal,
    hostSearchKey,
    searchKey,
    status,
    refresh,
    language,
    // numSpeakersGte,
    // numSpeakersLte,
  ])

  const handleChangeLimit = (event: any) => {
    setLimit(parseInt(event.target.value))
    setPage(1)
  }

  const handleCreatedSortKeyChange = () => {
    setSortKey(sortKey === 'createDesc' ? 'createAsc' : 'createDesc')
  }

  const handleUpdatedSortKeyChange = () => {
    setSortKey(sortKey === 'updateDesc' ? 'updateAsc' : 'updateDesc')
  }

  const cols = [
    '',
    'ID',
    'Name',
    <TableSortLabel
      active={['createAsc', 'createDesc'].includes(sortKey)}
      direction={sortKey === 'createAsc' ? 'asc' : 'desc'}
      onClick={handleCreatedSortKeyChange}
    >
      Created At
    </TableSortLabel>,
    <TableSortLabel
      active={['updateAsc', 'updateDesc'].includes(sortKey)}
      direction={sortKey === 'updateAsc' ? 'asc' : 'desc'}
      onClick={handleUpdatedSortKeyChange}
    >
      Updated At
    </TableSortLabel>,
    'Status',
    'Language',
    'Host',
    'Duration',
    'NumSpk',
    'View',
    'Download',
    'Upload Transcript',
    'Resubmit',
    'Delete',
  ]

  return (
    <React.Fragment>
      <div style={{ display: 'flex' }}>
        <h1>Conversations</h1>
        <IconButton onClick={() => setRefresh(!refresh)}>
          <Refresh />
        </IconButton>
      </div>

      <Paper className={classes.filter}>
        <Typography variant="h6">
          <strong>Filters</strong>
        </Typography>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControl className={classes.formControl}>
            <InputLabel id="status-filter-label">Status</InputLabel>
            <Select
              labelId="status-filter-label"
              id="status-filter"
              value={status}
              onChange={(e: any) => {
                setStatus(e.target.value)
                setPage(1)
              }}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {api.Statuses.map((s: string) => (
                <MenuItem key={s} value={s}>
                  {s.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="language-filter-label">Language</InputLabel>
            <Select
              labelId="language-filter-label"
              id="language-filter"
              value={language}
              onChange={(e: any) => {
                setLanguage(e.target.value)
                setPage(1)
              }}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {api.Languages.map((l: string) => (
                <MenuItem key={l} value={l}>
                  {l}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              type="date"
              label="Date Created-From"
              value={createFromVal}
              InputLabelProps={{ shrink: true }}
              inputProps={{ max: createToVal }}
              onChange={(e: any) => {
                setCreateFromVal(e.target.value)
                setPage(1)
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              type="date"
              label="Date Created-To"
              value={createToVal}
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: createFromVal }}
              onChange={(e: any) => {
                setCreateToVal(e.target.value)
                setPage(1)
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              label="Search Conversations by Name"
              style={{ width: 300 }}
              id="conversation-search"
              InputLabelProps={{ shrink: true }}
              onChange={lodash.debounce(
                (e: any) => {
                  setSearchKey(e.target.value)
                  setPage(1)
                },
                250,
                { maxWait: 1000 },
              )}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              label="Search Conversations by Host E-mail"
              style={{ width: 300 }}
              id="conversation-search-host"
              InputLabelProps={{ shrink: true }}
              onChange={lodash.debounce(
                (e: any) => {
                  setHostSearchKey(e.target.value)
                  setPage(1)
                },
                250,
                { maxWait: 1000 },
              )}
            />
          </FormControl>
          {/* <div style={{ display: 'flex' }}>
            <FormControl className={classes.formControl} style={{ width: 40 }}>
              <TextField
                type="number"
                label="NumSpk"
                value={numSpeakersGte}
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: 1, max: numSpeakersLte }}
                onChange={(e: any) => {
                  setNumSpeakerGte(e.target.value)
                  setPage(1)
                }}
              />
            </FormControl>
            <p style={{ fontSize: 'xx-large', marginTop: 15 }}> - </p>
            <FormControl
              className={classes.formControl}
              style={{ width: 40, marginTop: 24 }}
            >
              <TextField
                type="number"
                label=""
                value={numSpeakersLte}
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: numSpeakersLte }}
                onChange={(e: any) => {
                  setNumSpeakerLte(e.target.value)
                  setPage(1)
                }}
              />
            </FormControl>
          </div> */}
        </div>
        <br />
        <Button
          onClick={handleReset}
          variant="outlined"
          className={classes.resetButton}
        >
          Reset
        </Button>
      </Paper>
      <br />
      <Paper>
        <TableContainer style={{ maxHeight: 800 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {cols.map((col, index) => (
                  <TableCell key={index}>
                    <strong>{col}</strong>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {conversations.map((conv) => (
                <CollapsibleRow
                  key={conv._id}
                  conversation={conv}
                  refresh={refresh}
                  setRefresh={setRefresh}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={total}
          rowsPerPage={limit}
          page={page - 1} // Table's pages start at 0, API's start at 1
          onChangePage={(event, newPage) => setPage(newPage + 1)}
          onChangeRowsPerPage={handleChangeLimit}
        />
      </Paper>
    </React.Fragment>
  )
}

function CollapsibleRow({ conversation, refresh, setRefresh }: any) {
  const [open, setOpen] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openViewDialog, setOpenViewDialog] = useState(false)
  const [sentences, setSentences] = useState([])

  const { addToast, removeToast } = useToasts()

  const deleteConversation = async () => {
    try {
      await api.deleteConversation(conversation._id)
      setRefresh(!refresh)
      setOpenDeleteDialog(false)
      addToast(
        `Conversation ${conversation.name}, ${conversation._id} deleted successfully`,
        {
          appearance: 'success',
        },
      )
    } catch (err) {
      console.log(err)
      addToast(
        `Failed to delete Conversation ${conversation.name}, ${conversation._id}: ${err.message}`,
        {
          appearance: 'error',
        },
      )
    }
  }

  const downloadConversation = async () => {
    addToast(
      `Downloading Conversation ${conversation.name}, ${conversation._id}`,
      {
        id: 'downloadConvInfo',
        appearance: 'info',
        autoDismiss: false,
      },
    )
    try {
      const fetchedConv = await api.exportConversation(conversation._id)
      const blob = new Blob([fetchedConv], {
        type: 'application/zip',
      })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute(
        'download',
        `${conversation.name}_${conversation._id}.zip`,
      )
      link.href = url
      link.click()
      removeToast('downloadConvInfo')
      addToast(
        `Conversation ${conversation.name}, ${conversation._id} downloaded successfully`,
        {
          appearance: 'success',
        },
      )
    } catch (err) {
      console.log(err)
      removeToast('downloadConvInfo')
      addToast(
        `Failed to download Conversation ${conversation.name}, ${conversation._id}: ${err.message}`,
        {
          appearance: 'error',
        },
      )
    }
  }

  const resubmitConversation = async () => {
    try {
      await api.resubmit(conversation)
      setRefresh(!refresh)
      addToast(
        `Conversation ${conversation.name}, ${conversation._id} Resubmitted`,
        {
          appearance: 'success',
        },
      )
    } catch (err) {
      addToast(
        `Failed to resubmit Conversation ${conversation.name}, ${conversation._id}: ${err.message}`,
        {
          appearance: 'error',
        },
      )
    }
  }

  const viewConversation = async () => {
    try {
      const fetchedSentences = await api.getSentences(conversation._id)
      setSentences(fetchedSentences)
      setOpenViewDialog(true)
    } catch (err) {
      console.log(err)
      addToast(
        `Failed to fetch ${conversation.name}, ${conversation._id}: ${err.message}`,
        {
          appearance: 'error',
        },
      )
    }
  }

  const DeleteConfirmationDialog = () => (
    <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
      <DialogTitle>
        Delete {conversation.name}, {conversation._id}
      </DialogTitle>
      <DialogContent>
        Are you sure you want to delete{' '}
        <strong>
          Conversation {conversation.name}, {conversation._id}
        </strong>
        ?
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ margin: 5 }}
            onClick={deleteConversation}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: 5 }}
            onClick={() => setOpenDeleteDialog(false)}
          >
            No
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )

  let rowRenders: any = {
    id: conversation._id,
    name: conversation.name,
    createdAt: new Date(conversation.createdAt).toUTCString(),
    updatedAt: new Date(conversation.updatedAt).toUTCString(),
    status: conversation.status.toUpperCase(),
    language: conversation.language,
    host: conversation.host ? conversation.host.email : '',
    duration: displayDuration(conversation.finalAudio.duration),
    numSpeakers: conversation.numSpk,
    view: (
      <IconButton
        aria-label="view-conversation"
        onClick={viewConversation}
        disabled={conversation.finalAudio.cloudLink.length <= 0}
      >
        <Visibility />
      </IconButton>
    ),
    downloadConversation: (
      <IconButton
        aria-label="download-transcript"
        disabled={['in-lobby', 'in-progress', 'waiting-upload'].includes(
          conversation.status,
        )}
        onClick={downloadConversation}
      >
        <CloudDownload />
      </IconButton>
    ),
    uploadTranscript: (
      <Dropzone
        onDrop={async (acceptedFiles) => {
          try {
            await api.uploadTranscript(conversation, acceptedFiles[0])
            setRefresh(!refresh)
            addToast(
              `Transcript for ${conversation.name}, ${conversation._id} uploaded successfully`,
              {
                appearance: 'success',
              },
            )
          } catch (err) {
            addToast(
              `Failed to upload transcript for ${conversation.name}, ${conversation._id}: ${err.message}`,
              {
                appearance: 'error',
              },
            )
            console.log(err)
          }
        }}
        disabled={
          !['done', 'waiting-manual-transcription'].includes(
            conversation.status,
          )
        }
        accept={['.TextGrid']}
        maxFiles={1}
        noDrag
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <IconButton
                disabled={
                  !['done', 'waiting-manual-transcription'].includes(
                    conversation.status,
                  )
                }
              >
                <CloudUpload />
              </IconButton>
            </div>
          </section>
        )}
      </Dropzone>
    ),
    resubmit: (
      <IconButton
        aria-label="resubmit"
        disabled={
          !['done', 'error', 'waiting-manual-transcription'].includes(
            conversation.status,
          )
        }
        onClick={resubmitConversation}
      >
        <SettingsBackupRestore />
      </IconButton>
    ),
    delete: (
      <IconButton
        aria-label="delete-conversation"
        onClick={() => setOpenDeleteDialog(true)}
      >
        <DeleteIcon />
      </IconButton>
    ),
  }

  const collapseRowRenders: any = {
    user: [
      'User',
      (speaker: any) =>
        speaker.user.name
          ? `${processName(speaker.user.name)}, ${speaker.user.email}`
          : '',
    ],
    uploaded: ['Uploaded', (speaker: any) => speaker.audio.uploaded.toString()],
    group: ['Group', (speaker: any) => speaker.user.group],
    duration: [
      'Duration',
      (speaker: any) => displayDuration(speaker.audio.duration),
    ],
    canView: ['Can View', (speaker: any) => speaker.canView.toString()],
    viewed: ['Viewed', (speaker: any) => speaker.viewed.toString()],
    channel: ['Channel', (speaker: any) => speaker.channel],
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand-row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        {Object.keys(rowRenders).map((key) => (
          <TableCell key={key}>{rowRenders[key]}</TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={Object.keys(rowRenders).length}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={2} display="flex">
              <div style={{ margin: 10 }}>
                <Typography variant="h6" gutterBottom component="div">
                  <strong>Speakers</strong>
                </Typography>
                <Table size="small" aria-label="speakers">
                  <TableHead>
                    <TableRow>
                      {Object.keys(collapseRowRenders).map((key) => (
                        <TableCell key={key}>
                          <strong>{collapseRowRenders[key][0]}</strong>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {conversation.speakers.map((speaker: any) => (
                      <TableRow key={speaker._id}>
                        {Object.keys(collapseRowRenders).map((key) => (
                          <TableCell key={key}>
                            {collapseRowRenders[key][1](speaker)}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              {conversation.logs.length > 0 && (
                <div style={{ margin: 10 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    <strong>Logs</strong>
                  </Typography>
                  <Table size="small" aria-label="logs">
                    <TableHead>
                      <TableRow>
                        {['Created At', 'Content'].map((key) => (
                          <TableCell key={key}>
                            <strong>{key}</strong>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {conversation.logs.map((log: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell>
                            {new Date(log.createdAt).toUTCString()}
                          </TableCell>
                          <TableCell>{log.content}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              )}
              {conversation.error && (
                <div style={{ margin: 10 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    <strong>Error</strong>
                  </Typography>
                  <ul style={{ fontSize: 'large' }}>
                    <li>
                      <b>Message:</b> {conversation.error.message}
                    </li>
                    <li>
                      <b>Detail:</b> {conversation.error.detail}
                    </li>
                  </ul>
                </div>
              )}
              {conversation.appInfo && (
                <div style={{ margin: 10 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    <strong>App Info</strong>
                  </Typography>
                  <ul style={{ fontSize: 'large' }}>
                    {
                      Object.keys(conversation.appInfo).map(key => 
                      (<li>
                        <b>{key}:</b> {conversation.appInfo[key]}
                      </li>))
                    }
                  </ul>
                </div>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {openDeleteDialog && <DeleteConfirmationDialog />}
      {openViewDialog && (
        <ViewDialog
          openViewDialog={openViewDialog}
          setOpenViewDialog={setOpenViewDialog}
          conversation={conversation}
          sentences={sentences}
        />
      )}
    </React.Fragment>
  )
}

function ViewDialog({
  openViewDialog,
  setOpenViewDialog,
  conversation,
  sentences,
}: any) {
  const handleClose = () => {
    setOpenViewDialog(false)
  }

  return (
    <Dialog
      onClose={handleClose}
      open={openViewDialog}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <strong>
          {conversation.name}, {conversation._id}
        </strong>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6">Audio</Typography>
        <audio
          controls
          style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}
        >
          <source src={conversation.finalAudio.cloudLink} />
        </audio>
        {sentences.length > 0 && (
          <React.Fragment>
            <Typography variant="h6">Transcription</Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {['Time', 'Speaker', 'Text'].map((text, index) => (
                    <TableCell key={index}>
                      <strong>{text}</strong>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sentences.map((sentence: any, index: number) => (
                  <TableRow
                    style={{
                      backgroundColor: index % 2 ? '#fafafa' : '#eeeeee',
                    }}
                  >
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      {displayAudioTime(sentence.startTime)} -{' '}
                      {displayAudioTime(sentence.endTime)}
                    </TableCell>
                    <TableCell>{sentence.user.email}</TableCell>
                    <TableCell>
                      {sentence.words.map((word: any) => word.text).join(' ')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <br />
          </React.Fragment>
        )}
      </DialogContent>
    </Dialog>
  )
}
